.gw__req-form-anchor {
  overflow-anchor: none;
}

.gw__invalid-name {
  color: $red;
  font-size: .6rem;
  font-weight: $font-weight-extra-bold;
  letter-spacing: .06rem;
  padding: -.6rem;
  margin: 0;
}

.gw__table-container {
  padding-right: 15px;
}

.gw__secondary-label {
  font-weight: $font-weight-light;
  font-style: italic;
}

.gw__invalid-requirement-name {
  color: $red;
}

.gw__delete-req-value-icon {
  font-size: 2.5rem !important;
  cursor: pointer;
}

.gw__center-delete-icon {
  padding: 1.5rem 0.3rem !important;
}