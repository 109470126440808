.gw__nav-logo {
  font-size: 27px;
  font-family: $standard;
  letter-spacing: -0.11px;
  line-height: 60px;
  text-align: center;
  padding-left: 1.5rem;

  // The bolded 'Go'
  .gw__nav-title-go {
    font-weight: $font-weight-bold;
  }

  // The lightly bolded 'Work'
  .gw__nav-title-work {
    font-weight: $font-weight-light;
  }

  .gw__nav-title-dot {
    font-weight: $font-weight-extra-bold;
    color: $green;
    font-size: 30px;
    padding-left: 2px;
  }
}

.gw__nav_links {
  color: inherit;
  text-decoration: inherit;

  &:hover{
    color: inherit;
    text-decoration: inherit;
  }
}

.gw__nav-subtitle {
  font-weight: $font-weight-light;
  font-family: $standard;
  font-size: 18px;
  letter-spacing: -0.11px;
  line-height: 35px;
  text-align: center;
}

.wapa-name {
  font-size: 1.25rem;
  cursor: pointer;
}

.display-name {
  font-size: 1.25rem;
}

.wapa-name-link:hover{
  text-decoration: none;
}

.gw__header-row {
  margin: 0rem;
  border-bottom: .5px solid #DEDEDE;

  &.gw__header-shadow {
    box-shadow: 1px 3px 10px 1px rgba(188,188,188,0.41);
  }
}

.gw__selected-country-client {
  font-family: $headings;
  font-weight: $font-weight-normal;
  color: $gray;
  font-size: 20px;
  line-height: 35px;
}

.header-country-vertical {
  border-left: 1px solid #777777;
}

.gw__header-link {
  font-size: .7rem;
  margin-top: 1.8rem;
  color: $green;
  text-decoration: underline;
  margin-left: 0.5rem;
}

.header-dropdown-menu {
  margin-right: 11rem;
  margin-top: 1.5rem;
  border-top: none;
}

.navigation-link:hover {
  text-decoration: none;
}

.country-navigation-row{
  padding: 0rem;
  margin: 0rem;
}

.gw__navigation-text {
  font-size: 0.9rem;
  color: $gray;
  font-weight: $font-weight-normal;
  font-family: $standard;

  &.gw__navigation-highlight {
    border-bottom: 5px solid $green;
    padding-bottom: 1rem;
    color: $black;
  }
}

.gw__country-client-container {
  margin-left: auto;
  margin-right: 2rem;
  margin-top: auto;
  margin-bottom: auto;
  order: 2;
  font-family: $standard;

  .gw__country-client-select {
    font-family: $headings;
    font-size: 0.9rem;
    font-weight: $font-weight-normal;
    color: $grey;

    &.gw__country-client-highlight {
      border-bottom: 5px solid $green;
      padding-bottom: 1rem;
      color: $black;
    }
  }
}

.gw__settings-toggle {
  order: 3;
  // margin-top: auto;
  color: $gray;
  cursor: pointer;
  align-self: center;
}

.country {
	color: #5C5C5C;
  font-size: 16px;
  position: absolute;
  right: 12vw;
  
  &:hover{
    color: #5C5C5C;
  }
}

.client {
	color: #5C5C5C;
  font-size: 16px;
  position: absolute;
  right: 9vw;
  
  &:hover{
    color: #5C5C5C;
  }
}