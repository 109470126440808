.form-control {
    color: $black;
    font-weight: $font-weight-light;
    background-color: $gray-text-box;
    border: 1px solid #DFDFDF;
    border-radius: 0;
    min-height: 4rem;

    &:focus {
        color: $black;
        border: 1px solid #DFDFDF;
        border-bottom: 3px solid $green;
        box-shadow: none;
        padding-bottom: 0.2rem;
    }

    &:disabled {
        background-color: $gray-text-box;
        color: $dark-gray;
    }

    &.text-area-sizing {
        min-height: 4rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        overflow: hidden;
    }

    &.gw__input-error {
        background-color: $error-text-box;
        border-bottom: 3px solid $red;
    }
}

.input-group-text {
    background-color: $light-gray;
}

.form-control[type="number"] {
    max-width: 4rem;
    border-left: none;
    border-right: none;
    text-align: center;

    &:focus {
        background-color: $gray-text-box;
        padding-bottom: 0.375rem;
        border: 1px solid #DFDFDF;
        border-left: none;
        border-right: none;
    }
}

.form-control[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.gw__number-arrows {
    color: #707070;
    font-weight: $font-weight-light;
    background-color: $gray-text-box;
    border: 1px solid #DFDFDF;
    min-height: 4rem;
    cursor: pointer;

    &.left {
        border-right: none;
        width: 3rem;
        padding-left: 1.3rem;
    }

    &.right {
        border-left: none;
        width: 3rem;
        padding-right: 1.3rem;
    }
}

input[type="radio"] {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border: 2px solid $gray;
    border-radius: 50%;

    &:hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &::before {
        content: '';
        display: block;
        width: 70%;
        height: 70%;
        display: grid;
        border-radius:50%;
        margin: 3.16px auto;
    }

    &:checked::before{
        background: $green;
        outline: none;
    }

    &:checked {
        border: 2px solid $green;
    }
}
