.message {
	position: fixed;
	top: 1rem;
	left: 0;
	width: 100%;
	padding: 0 1rem;
}

.gw__annonucement-type-container {
	padding-left: 1.2rem;
}
