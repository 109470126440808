.numeric-input-error {
  margin-top: 10px;
  color: #d9534f;
}

.gw__edit-permit-button {
  margin-top: auto;
}

.gw__delete-permit-icon {
  font-size: 2rem !important;
  cursor: pointer;
  margin-right: 1rem;
}

.gw__table-heading {
  font-weight: $font-weight-extra-bold;
  font-size: 0.8rem;
  color: $gray;
  letter-spacing: 1px;
}

.gw__table-items {
  font-weight: $font-weight-light;
  font-family: $standard;
  color: $black;
  font-size: 1rem;
}

.gw__custom-validity {
  align-items: flex-start;
}

.gw__select-dropdown {
  background-color: $gray;
}
