.order-permits-select-country {
  flex-direction: column;
  align-items: center;
}

.order-permits__edit {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.order-permits__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 1000;
}
