.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 25vh;
}

.continue-link-position {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 2vh;
}

.landing-page-dropdown-position {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

// Positioning for the divider between country and client select on the landing page
.align-divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 6vh;
  padding-bottom: 5vh;
}

// Styling for landing page and country/client select dropdown box.
.landing-page-select {
  width: 370px;
  text-align: left;
  font-size: 14px;	
  letter-spacing: -0.23px;
  font-weight: normal;
}

.dropdown-toggle::after {
  position: absolute;
  right: 20px;
  top: 22px;
 }

.label {
  height: 24px;
  width: 370px;
  color: #212529;
  font-size: 12px;
  letter-spacing: 0.7px;
  font-weight: normal;
  //line-height: 15px;
}

.input {
  height: 25px;
  width: 306px;
  color: #212529;
  font-size: 14px;
  letter-spacing: -0.23px;
  line-height: 25px;
}

// Style for the dividing line on the landing page.
.line-6 {
  box-sizing: border-box;
  height: 1px;
  width: 70px;
  border: 1px solid #979797;
}

// Style for dividing text.
.or {
  width: 50px;
  color: #5C5C5C;
  font-size: 18px;
  font-weight: 300;
  line-height: 5px;
  text-align: center;
}
