.modal {
    display: flex !important;
    align-items: center;
    width: 100%;
  }
  
  .modal-content {
    height: 100%;
    overflow: auto;
    padding: 1.5rem;
    padding-left: 3rem;
  }
  
  .modal-header {
    border: 0;
  }
  
  .modal-dialog {
    margin-right: auto;
    margin-left: auto;
    max-height: 90%;
  }
  
  .modal-footer {
    border: 0;
  }
  
  .modal-title {
    margin: 0;
    font-size: 1.5rem !important;
    font-weight: $font-weight-extra-bold;
    width: 100%;
  
    i {
      cursor: pointer;
    }
  }
  
  .modal-content-scroll {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  @media screen and (max-width: 768px) {
    .modal-dialog {
      max-width: 94% !important;
    }
  }
  