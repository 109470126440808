.table {
    border-spacing: 0;
    font-size: 1rem;
  
    thead th {
      border-top: 0;
      font-family: $headings;
      font-weight: $font-weight-extra-bold;
      color: $gray;
      text-transform: uppercase;
      font-size: .8rem;
    }
  
    tbody {
      th, td {
        border-top: 0;
        border-bottom: 2px solid #dee2e6;
        font-weight: $font-weight-light;
        font-family: $standard;
        color: $black;
        max-width: 35rem;
      }
    }
  }